var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Revenue")]), _c('feather-icon', {
    staticClass: "text-muted cursor-pointer",
    attrs: {
      "icon": "SettingsIcon",
      "size": "18"
    }
  })], 1), _c('b-card-body', {
    staticClass: "pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start mb-3"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_c('b-card-text', {
    staticClass: "mb-50"
  }, [_vm._v(" This Month ")]), _c('h3', {
    staticClass: "font-weight-bolder"
  }, [_c('sup', {
    staticClass: "font-medium-1"
  }, [_vm._v("$ ")]), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.revenue.thisMonth))])])], 1), _c('div', [_c('b-card-text', {
    staticClass: "mb-50"
  }, [_vm._v(" Last Month ")]), _c('h3', {
    staticClass: "font-weight-bolder"
  }, [_c('sup', {
    staticClass: "font-medium-1"
  }, [_vm._v("$ ")]), _c('span', [_vm._v(_vm._s(_vm.revenue.lastMonth))])])], 1)]), _c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "240",
      "options": _vm.revenueComparisonLine.chartOptions,
      "series": _vm.revenueComparisonLine.series
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }