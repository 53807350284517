var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Sessions By Device")]), _c('b-dropdown', {
    staticClass: "chart-dropdown ",
    attrs: {
      "text": "Last 7 Days",
      "variant": "transparent",
      "right": "",
      "no-caret": "",
      "toggle-class": "p-0 mb-25",
      "size": "sm"
    }
  }, _vm._l(_vm.chartData.lastDays, function (day) {
    return _c('b-dropdown-item', {
      key: day
    }, [_vm._v(" " + _vm._s(day) + " ")]);
  }), 1)], 1), _c('b-card-body', [_c('vue-apex-charts', {
    staticClass: "my-1",
    attrs: {
      "type": "donut",
      "height": "300",
      "options": _vm.sessionsByDeviceDonut.chartOptions,
      "series": _vm.sessionsByDeviceDonut.series
    }
  }), _vm._l(_vm.chartData.chartInfo, function (data, index) {
    return _c('div', {
      key: data.name,
      staticClass: "d-flex justify-content-between",
      class: index === _vm.chartData.chartInfo.length - 1 ? 'mb-0' : 'mb-1'
    }, [_c('div', {
      staticClass: "series-info d-flex align-items-center"
    }, [_c('feather-icon', {
      class: data.iconColor,
      attrs: {
        "icon": data.icon,
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bolder ml-75 mr-25"
    }, [_vm._v(_vm._s(data.name))]), _c('span', [_vm._v("- " + _vm._s(data.usage) + "%")])], 1), _c('div', [_c('span', [_vm._v(_vm._s(data.upDown) + "%")]), _c('feather-icon', {
      staticClass: "mb-25 ml-25",
      class: data.upDown > 0 ? 'text-success' : 'text-danger',
      attrs: {
        "icon": data.upDown > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon'
      }
    })], 1)]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }