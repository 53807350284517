var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Customers")]), _c('b-dropdown', {
    staticClass: "chart-dropdown ",
    attrs: {
      "text": "Last 7 Days",
      "variant": "transparent",
      "right": "",
      "no-caret": "",
      "toggle-class": "p-0 mb-25",
      "size": "sm"
    }
  }, _vm._l(_vm.chartData.lastDays, function (day) {
    return _c('b-dropdown-item', {
      key: day
    }, [_vm._v(" " + _vm._s(day) + " ")]);
  }), 1)], 1), _c('b-card-body', [_c('vue-apex-charts', {
    staticClass: "mt-2 mb-1",
    attrs: {
      "type": "pie",
      "height": "325",
      "options": _vm.customersPie.chartOptions,
      "series": _vm.customersPie.series
    }
  }), _c('div', {
    staticClass: "pt-25"
  }, _vm._l(_vm.chartData.listData, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex justify-content-between",
      class: index === Object.keys(_vm.chartData.listData).length - 1 ? '' : 'mb-1'
    }, [_c('div', {
      staticClass: "series-info"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      class: data.iconColor,
      attrs: {
        "icon": data.icon,
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(_vm._s(data.text))])], 1), _c('span', [_vm._v(_vm._s(data.result))])]);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }