var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('card-analytic-support-tracker')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('card-analytic-avg-sessions')], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('card-analytic-revenue-report')], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('card-analytic-goal-overview')], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('card-analytic-revenue')], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('card-analytic-sales-radar-chart')], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('card-analytic-sale-line-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('card-analytic-sessions-by-device')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('card-analytic-customers')], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('card-analytic-product-orders'), _c('card-analytic-earnings-chart')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }