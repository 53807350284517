var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Product Orders")]), _c('b-dropdown', {
    staticClass: "chart-dropdown ",
    attrs: {
      "text": "Last 7 Days",
      "variant": "transparent",
      "right": "",
      "no-caret": "",
      "toggle-class": "p-0",
      "size": "sm"
    }
  }, _vm._l(_vm.chartInfo.lastDays, function (day) {
    return _c('b-dropdown-item', {
      key: day
    }, [_vm._v(" " + _vm._s(day) + " ")]);
  }), 1)], 1), _c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "radialBar",
      "height": "325",
      "options": _vm.productOrdersRadialBar.chartOptions,
      "series": _vm.productOrdersRadialBar.series
    }
  }), _vm._l(_vm.chartInfo.chartInfo, function (data, key, index) {
    return _c('div', {
      key: key,
      staticClass: "d-flex justify-content-between",
      class: index === Object.keys(_vm.chartInfo.chartInfo).length - 1 ? '' : 'mb-1'
    }, [_c('div', {
      staticClass: "series-info d-flex align-items-center"
    }, [_c('feather-icon', {
      class: key === 'finished' ? 'text-primary' : key === 'pending' ? 'text-warning' : 'text-danger',
      attrs: {
        "icon": "CircleIcon",
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bold text-capitalize ml-75"
    }, [_vm._v(_vm._s(key))])], 1), _c('span', [_vm._v(_vm._s(data))])]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }