var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "align-items-baseline"
  }, [_c('div', [_c('b-card-title', {
    staticClass: "mb-25"
  }, [_vm._v(" Sales ")]), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" 2020 Total Sales: 12.84k ")])], 1), _c('feather-icon', {
    staticClass: "text-muted cursor-pointer",
    attrs: {
      "icon": "SettingsIcon",
      "size": "18"
    }
  })], 1), _c('b-card-body', {
    staticClass: "pb-0"
  }, [_c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "240",
      "options": _vm.salesLine.chartOptions,
      "series": _vm.salesLine.series
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }